import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import TeamList from '../components/teamList'

const TeamPage = () => {
  const data = useStaticQuery(graphql`
    query {
      team: markdownRemark (frontmatter: {templateKey: {eq: "team-page"}}) {
        frontmatter{
          title
          
          team_list {
            name
            position
            biog
            twitter
            image {
            childImageSharp{
              fluid(maxWidth: 500, quality: 80){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          }
        }
        html
      } 

      news: allMarkdownRemark (
              filter: { frontmatter: {templateKey: {eq: "news"}} },
              sort: { fields: frontmatter___order order: DESC },
              limit: 5
          ) {
          edges {
          node {
              frontmatter {
              title
              thumbnail {
                  childImageSharp {
                  fluid(maxWidth:800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
                  }
              }
              client_rel
              order
              }
              html
          }
          }
      }
    }
  `)
  //random number for image
  const rand = (Math.floor(Math.random() * 5))
  //console.log(rand)
  const topImage = data.news.edges[rand].node.frontmatter.thumbnail.childImageSharp.fluid
  //console.log(topImage)
  return (
    <Layout>
      <SEO title="Team"/>
     <section className="section team">
      <h1>{data.team.frontmatter.title}</h1>
      <div className="columns">
        <div className="column is-half">
          <Img fluid={topImage} alt="Team image"/>
        </div>
        <div className="column is-half team-header-text" dangerouslySetInnerHTML={{ __html: data.team.html}}></div>
        
      </div>
      <TeamList team={data.team.frontmatter.team_list}/>
      </section>
    </Layout>
  )
}

export default TeamPage

