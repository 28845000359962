import React from 'react'
import teamDefaultImage from '../images/team-default.jpg'
import {FaTwitter} from 'react-icons/fa'
import Img from 'gatsby-image'

const teamList = ({team}) => {
    return (
        <section className="team-list columns is-multiline">
            {team.map( (item) => {
                return(
                <div className="team-list-item column is-one-third" key={item.name}>
                    <div className="team-headshot">
                    {
                        item.image === null 
                        ? <img src={teamDefaultImage} width="200px" alt="team headshot"/>
                        : <Img fluid={item.image.childImageSharp.fluid}  alt="team headshot"/>
                    }    
                    
                    </div>
                    
                    <div className="team-title">
                    <header>
                        <h2>{item.name}</h2>
                        <h3>{item.position}</h3>
                    </header>
                    
                    { item.twitter &&
                    <a href={`https://twitter.com/${item.twitter}`}><FaTwitter/></a>
                    }
                    </div>
                    <div className="team-text">
                        <p>{item.biog}</p>
                    </div>
                   
                        
                </div>
                )
            })}
        </section>
    )
}

export default teamList